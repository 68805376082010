
export default {
    PRODUCT_TAG: 'tag',
    PRODUCT_CATEGORY: 'category',
    PRODUCT: 'product',
    ST_CARD: 'st-card',
    ACH_CARD: 'ach-card',
    COIN_CARD: 'coin-card',
    EDIT_ORDER_STATUS: 'order-status',
};
