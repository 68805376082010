import { combineReducers } from 'redux-immutable';
import { routerReducer } from 'react-router-redux';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';
import { RouteReducer } from './RouteReducer';
import { GlobalAlertReducer } from './GlobalAlertReducer';
import { OrderReducer } from './OrderReducer';
import { PaymentReducer } from './PaymentReducer';
import { STCardReducer } from './STCardReducer';
import { STGroupReducer } from './STGroupReducer';
import { IbanCardReducer } from './IbanCardReducer';
import { AchCardReducer } from './AchCardReducer';
import { CoinReducer } from './CoinReducer';
import { WithdrawReducer } from './WithdrawReducer';
import { DashboardReducer } from './DashboardReducer';
import { UserReducer } from './UserReducer';

const appReducer = combineReducers({
  [ReducerNameConstants.ROUTE]: RouteReducer,
  [ReducerNameConstants.GLOBAL_ALERT]: GlobalAlertReducer,
  [ReducerNameConstants.ORDER]: OrderReducer,
  [ReducerNameConstants.PAYMENT]: PaymentReducer,
  [ReducerNameConstants.USER]: UserReducer,
  [ReducerNameConstants.ST_CARD]: STCardReducer,
  [ReducerNameConstants.ST_GROUP]: STGroupReducer,
  [ReducerNameConstants.IBAN_CARD]: IbanCardReducer,
  [ReducerNameConstants.ACH_CARD]: AchCardReducer,
  [ReducerNameConstants.COIN]: CoinReducer,
  [ReducerNameConstants.WITHDRAW]: WithdrawReducer,
  [ReducerNameConstants.DASHBOARD]: DashboardReducer,

  router: routerReducer,
});

const rootReducer = (state, action) => {
  let newState = state;
  return appReducer(newState, action);
};

export default rootReducer;
