const Endpoint = {
    LOGIN: '/user/login',
    LOGOUT: '/user/logout',
    TELEGRAM_CODE: '/User/telms',
    
    ORDER_GRAPH: '/home/graphOrderList',
    WITHDRAW_GRAPH: '/home/graphWithdrawList',
    MONEY: '/money/moneyLogList',
    ORDER: '/order/getOdersList',
    ORDER_DETAIL: '/Orderdetail/getOrder',
    ORDER_STATISTICS: '/order/orderData',
    ORDER_PAYMENT_STATUS: '/Order/Updateorder',
    NOTIFY: '/order/notify',

    WITHDRAW_LIST: '/money/getWithdrawList',
    WITHDRAW: '/money/addWithdraw',
    WITHDRAW_ACTION: '/money/actionWithdraw',
    ME: '/user/getUserinfo',

    USER: '/user/getUsers',
    UNPAGED_USER: '/user/getUserslist',
    EDIT_USER: '/user/editUser',

    ST_CARD: '/foreigner/getStripeList',
    EDIT_ST_CARD: '/foreigner/editStripe',
    DELETE_ST_CARD: '/foreigner/delStripe',

    ST_GROUP: '/foreigner/getStripegroupList',
    EDIT_ST_GROUP: '/foreigner/editStripegroup',

    IBAN_CARD: '/foreigner/getCardList',
    EDIT_IBAN_CARD: '/foreigner/editCard',

    ACH_CARD: '/Accounttable/getAccounttableList',
    EDIT_ACH_CARD: '/Accounttable/setAccounttable',
    ACH_CARD_DETAIL: '/Accounttable/getAccounttable',
    DELETE_ACH_CARD: '/Accounttable/delAccounttable',

    COIN: '/Accounttable/getAccounttableList',
    EDIT_COIN: '/Accounttable/setAccounttable',
    COIN_DETAIL: '/Accounttable/getAccounttable',
    DELETE_COIN: '/Accounttable/delAccounttable',

    UPLOAD: '/common/upload',

    CURRENCY_LIST: '/order/getBiZhong',
}
export default Endpoint;

export const BASE_URL = 'https://stcrof.rateose.store';