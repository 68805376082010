import ActionConstants from '../constants/actions/ActionConstants';
import * as STGroupAPI from '../apis/STGroupAPI';
import { showErrorGlobalAlert, showSuccessGlobalAlert } from './globalAlertActionCreator';

export const updateStGroupListAction = (stGroupList, total) => ({
    type: ActionConstants.UPDATE_ST_GROUP_LIST,
    payload: { data: stGroupList, total: total },
});

export const getStGroupList = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            STGroupAPI.fetchSTGroupList(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        const stGroupList = res.data.data.list;
                        const total = res.data.data.total;
                        dispatch(updateStGroupListAction(stGroupList, total));
                        return resolve(res.data.data);
                    }
                    return reject('err')
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const getStGroupInfoById = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            STGroupAPI.getSTGroupInfoById(id)
                .then((res) => {
                    console.error(2222222222);
                    console.error(res);
                    if (res.status === 200) {
                        return resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const editSTGroupInfoById = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            STGroupAPI.editSTGroupInfoById(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        dispatch(showSuccessGlobalAlert('edit-st-info-succeed'));
                        return resolve('success');
                    } else {
                        dispatch(showErrorGlobalAlert(res.data.msg));
                        return reject(res.data.msg)
                    }
                })
                .catch((err) => {
                    dispatch(showErrorGlobalAlert('edit-st-info-failed'));
                    return reject(err)
                });
        });
    };
}