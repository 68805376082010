import React, { Component } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import Pagination from '@material-ui/lab/Pagination';
import Switch from '@material-ui/core/Switch';

// --- Components --- //
import Loading from '../../components/common/LoadingIndicator';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';

// --- Icons --- //
import SearchIcon from '../../icons/SearchIcon';
import CommentIcon from '../../icons/CommentIcon';
import WebsiteIcon from '../../icons/WebsiteIcon';
import DollarIcon from '../../icons/DollarIcon';
import JPYIcon from '../../icons/JPYIcon';
import EURIcon from '../../icons/EURIcon';
import GBPIcon from '../../icons/GBPIcon';
import CalendarIcon from '../../icons/CalendarIcon';

// --- Constants --- //
import ColorConstants from '../../constants/ColorConstants';
import DialogConstants from '../../constants/dialog/index';

// --- Actions --- //
import * as stCardActionCreator from '../../actions/stCardActionCreator';
import * as basicConfigActionCreator from '../../actions/basicConfigActionCreator';

// --- Selectors --- //
import * as SdCardSelectors from '../../selectors/SdCardSelectors';

import I18N from '../../i18n';
import RDSButton from '../../components/common/RDSButton';
import PagePathConstants from '../../constants/router/PagePathConstants';
import DeleteConfirmationModal from '../../components/common/dialog/DeleteConfirmationModal';
import classNames from 'classnames';

const ORDER_FILTER_TYPE = [{
  id: '',
  text: 'All'
}, {
  id: 0,
  text: 'Off'
}, {
  id: 1,
  text: 'T2'
}, {
  id: 2,
  text: 'T7'
},];

class STCardPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      sdCardListParams: {
        page: 1,
        per_page: 5,
        // keywords: '',
        status: '',
      },
      currencyList: null,
      showLoading: false,
      showDeleteConfirmModal: false,
      deleteItemId: '',
    };

    this.getSTRecordList = this.getSTRecordList.bind(this);
    this.handleSdCardFilterClick = this.handleSdCardFilterClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.handleCommentFilterChange = this.handleCommentFilterChange.bind(this);
    this.handleEditSdCard = this.handleEditSdCard.bind(this);
    this.handleDeleteSdCard = this.handleDeleteSdCard.bind(this);
    this.createNewSdCard = this.createNewSdCard.bind(this);
    this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
    this.handleDeleteSTCard = this.handleDeleteSTCard.bind(this);
    this.fetchCurrencyList = this.fetchCurrencyList.bind(this);
    this.renderCurrencySymbol = this.renderCurrencySymbol.bind(this);
  }

  componentDidMount() {
    this.getSTRecordList();
    this.fetchCurrencyList();
  }

  renderCurrencySymbol(currency) {
    switch (currency) {
      case 'jpy': {
        return <JPYIcon size={14} color={ColorConstants.black[0]} className="tw-mb-[3px]" />
      }
      case 'usd': {
        return <DollarIcon size={14} color={ColorConstants.black[0]} className="tw-mb-[3px]" />
      }
      case 'eur': {
        return <EURIcon size={14} color={ColorConstants.black[0]} className="tw-mb-[3px]" />
      }
      case 'gbp': {
        return <GBPIcon size={14} color={ColorConstants.black[0]} className="tw-mb-[3px]" />
      }
      default: {
        return <JPYIcon size={14} color={ColorConstants.black[0]} className="tw-mb-[3px]" />
      }

    }
  }

  fetchCurrencyList() {
    const { basicConfigActions } = this.props;
    basicConfigActions && basicConfigActions.fetchCurrencyList()
      .then((data) => {
        const currencyList = data && data.map(item => {
          return {
            value: item.code,
            label: item.currency
          }
        });
        this.setState({ currencyList });
      });
  }

  toggleDeleteConfirmModal() {
    this.setState({ showDeleteConfirmModal: !this.state.showDeleteConfirmModal });
  }

  handleEditSdCard(id) {
    const { history } = this.props;
    history && history.push(PagePathConstants.ST_EDIT.replace(':id', id));
  }

  handleDeleteSdCard(id) {
    this.toggleDeleteConfirmModal();
    this.setState({ deleteItemId: id });
  }

  handleDeleteSTCard() {
    const { stCardActions } = this.props;
    const { deleteItemId } = this.state;

    stCardActions && (deleteItemId && deleteItemId !== '') && stCardActions.deleteSTCardInfoById(this.state.deleteItemId)
      .then(data => {
        this.toggleDeleteConfirmModal();
        this.getSTRecordList();
        return;
      })
      .catch(this.toggleDeleteConfirmModal);
  }

  toggleLoading() {
    this.setState({ showLoading: !this.state.showLoading });
  }

  handleCommentFilterChange(e) {
    const comment = e && e.target.value;
    const { sdCardListParams } = this.state;
    sdCardListParams.keywords = comment;
    this.setState({ sdCardListParams });
  }

  getSTRecordList(params) {
    if (!params) {
      params = this.state.sdCardListParams;
    }
    const { stCardActions } = this.props;
    this.toggleLoading();
    return stCardActions && stCardActions.getSdCardList(params)
      .then(data => {
        this.toggleLoading();
        return;
      });
  }

  handleSdCardFilterClick(status) {
    let { sdCardListParams } = this.state;
    sdCardListParams.status = status.id;
    sdCardListParams.page = 1;
    this.setState({ sdCardListParams });
    this.getSTRecordList(sdCardListParams);
  }

  handlePageChange(e, page) {
    let { sdCardListParams } = this.state;
    sdCardListParams.page = page;
    this.getSTRecordList(sdCardListParams)
      .then(() => {
        this.setState({ sdCardListParams });
      });
  }

  createNewSdCard() {
    const { history } = this.props;
    history && history.push(PagePathConstants.ST_ADD);
  }

  renderSTCardStatus(state) {
    switch (state) {
      case 2: {
        return (<div className='tw-min-w-[80px] tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[12px] tw-bg-status-notified-bg tw-text-white tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('t7')}</span>
        </div>)
      }
      case 1: {
        return (<div className='tw-min-w-[80px] tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[12px] tw-bg-status-succeed-bg tw-text-white tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('t2')}</span>
        </div>)
      }
      case 0:
      default: {
        return (<div className='tw-min-w-[80px] tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[12px] tw-bg-status-timeout-bg tw-text-white tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('off')}</span>
        </div>)
      }
    }
  }

  render() {
    const { stCardList, stCardTotalNum } = this.props;
    const { sdCardListParams, showLoading, showDeleteConfirmModal } = this.state;
    const total = Math.ceil(stCardTotalNum / sdCardListParams.per_page);
    return (<div className='tw-w-full tw-px-[50px]'>
      {showLoading && <Loading />}
      <DeleteConfirmationModal isDeleteModalOpen={showDeleteConfirmModal} toggle={this.toggleDeleteConfirmModal} handleDeleteConfirm={this.handleDeleteSTCard} sectionName={DialogConstants.ST_CARD} />
      <div className='tw-w-full tw-mt-[30px]'>
        <div className='tw-flex tw-justify-between tw-items-center tw-w-full tw-mt-[20px] hs-userlist-filter-container'>
          {/* <EDSInput className="tw-border-b-1 tw-border-line-1 tw-w-[450px] tw-pl-[10px]"
            value={sdCardListParams.keywords}
            type="text"
            placeholder={I18N.getText('keywords-placeholder')}
            onChange={this.handleCommentFilterChange}
          /> */}
          {/* <div className='tw-relative'>
            <EDSInput className="tw-w-[450px] tw-py-[12px] tw-rounded-[30px] tw-px-[20px] tw-border-none tw-shadow-xl"
              value={sdCardListParams.keywords}
              type="text"
              placeholder={I18N.getText('keywords-placeholder')}
              onChange={this.handleCommentFilterChange}
            />
            <SearchIcon size={24} color={ColorConstants.black[0]} className="tw-absolute tw-right-[20px] tw-bottom-[12px] tw-w-[24px] tw-h-[24px]" onClick={this.handleSdCardFilterClick} />
          </div> */}

          <div className='tw-flex tw-w-full'>
            {ORDER_FILTER_TYPE.map(item => {
              return <div key={item.id} className={classNames('tw-h-[40px] tw-flex tw-justify-center tw-items-center tw-rounded-[10px] tw-text-body-text-1 tw-text-[14px] tw-font-bold tw-mr-[30px] tw-text-[#717579]', { '!tw-text-brand-primary': sdCardListParams.status === item.id })} onClick={() => this.handleSdCardFilterClick(item)}>{item.text}</div>
            })}
          </div>
          <div className='tw-w-[300px] tw-flex tw-justify-end tw-items-center'>
            <RDSButton className="tw-text-white" onClick={this.createNewSdCard}>Create</RDSButton>
          </div>
        </div>

        {stCardList && stCardList.length > 0 && <React.Fragment>
          <div className='tw-w-full tw-mt-[30px]'>

            <div className='tw-w-full hs-users-table-container'>
              <div className='tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[14px] tw-mb-[20px] tw-rounded-[30px] tw-bg-white tw-h-[80px] tw-shadow-xl'>
                <div className='tw-w-[15%]'>{I18N.getText('comment')}</div>
                <div className='tw-w-[5%]'>{I18N.getText('grouping')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('max-money')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('max-order')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('total-money')}</div>
                <div className='tw-w-[8%]'>{I18N.getText('order-count')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('status')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('date')}</div>
                <div className='tw-w-[12%]'>{I18N.getText('description')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('operation')}</div>
              </div>
              <div className='tw-w-full tw-text-[12px]'>
                {stCardList && stCardList.map(record => {
                  return (<div className='tw-rounded-[30px] tw-h-[80px] tw-bg-white tw-overflow-hidden tw-flex tw-justify-between tw-items-center tw-text-center tw-mb-[20px] tw-shadow-xl hs-table-row'>
                    <div className='tw-w-[15%] tw-flex tw-justify-center tw-items-center'>
                      <WebsiteIcon size={24} color={ColorConstants.black[0]} />
                      <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{record.comment}</span>
                    </div>
                    <div className='tw-w-[5%] tw-flex tw-justify-center tw-items-center tw-font-bold hs-td-cell'>{record.group_name}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      {this.renderCurrencySymbol(record.currency)}

                      <span className='tw-font-bold hs-td-cell'>{record.max_money}</span>
                    </div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center tw-font-bold hs-td-cell'>{`${record.max_order}`}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center hs-td-cell'>{`${record.money_sum}`}</div>
                    <div className='tw-w-[8%] tw-flex tw-justify-center tw-items-center hs-td-cell'>{`${record.order_count}`}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>{this.renderSTCardStatus(record.status)}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <CalendarIcon size={24} color={ColorConstants.black[0]} className="tw-mb-[2px]" />
                      <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{record.createtime}</span>
                    </div>
                    <div className='tw-w-[12%] tw-flex tw-justify-center tw-items-center'>
                      <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{record.description}</span>
                    </div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <SvgIcon onClick={() => this.handleEditSdCard(record.id)} size={20} color={ColorConstants.brand.primary} name="edit-icon" />
                      <SvgIcon onClick={() => this.handleDeleteSdCard(record.id)} size={20} color={ColorConstants.brand.primary} name="delete-icon" />
                    </div>
                  </div>)
                })}
              </div>
            </div>
          </div>
          <div className='tw-w-full tw-flex tw-justify-center'>
            <Pagination count={total} page={sdCardListParams.page} onChange={this.handlePageChange} variant="outlined" color="primary" />
          </div>
        </React.Fragment>}
        {stCardList && stCardList.length === 0 && <div className='tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center'>
          <SvgIcon size={200} name="non-record-icon" color={ColorConstants.brand.primary} />
          <div className='tw-mt-[20px] tw-text-[16px] tw-font-medium'>{I18N.getText('no-records')}</div>
        </div>}
      </div>
    </div>);
  }
}

function mapStateToProps(state) {
  return {
    stCardList: SdCardSelectors.selectSdCardList(state),
    stCardTotalNum: SdCardSelectors.selectStRecordTotalNum(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    stCardActions: bindActionCreators(stCardActionCreator, dispatch),
    basicConfigActions: bindActionCreators(basicConfigActionCreator, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(STCardPage);

