
import ActionConstants from '../constants/actions/ActionConstants';
// import * as PaymentAPI from '../apis/PaymentAPI';
import * as OrderAPI from '../apis/OrderAPI';
import * as NotifyAPI from '../apis/NotifyAPI';
import { showErrorGlobalAlert, showInfoGlobalAlert, showSuccessGlobalAlert } from './globalAlertActionCreator';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';
import moment from 'moment';

export const updateOrderList = (data) => ({
    type: ActionConstants.UPDATE_ORDER_LIST,
    payload: data,
});

export const updateFailedOrderList = (data) => ({
    type: ActionConstants.UPDATE_FAILED_ORDER_LIST,
    payload: data,
});


export const fetchOrderRecord = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {

            OrderAPI.getOrderRecord(params)
            .then((res) => {
                if (res.status === 200 && res.data.code === 1) {
                    const ordertArr = res.data.data.list;
                    const total = res.data.data.total;
                    dispatch(updateOrderList({
                        data: ordertArr,
                        total
                    }));
                    return resolve('success');
                } else {
                    return reject(res.data.msg)
                }
            })
            .catch((err) => {
                dispatch(showErrorGlobalAlert('order-record-error'));
                return reject(err);
            });
        });
    };
};

export const informOrder = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            NotifyAPI.notifyOrder(id)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(showInfoGlobalAlert('order-notify-succeed'));
                    return resolve('success');
                }
            })
            .catch((err) => {
                dispatch(showErrorGlobalAlert('order-notify-failed'));
            });
        });
    };
};

export const fetchOrderFailedRecord = (params, callback) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            
            OrderAPI.getOrderRecord(params)
            .then((res) => {
                if (res.status === 200) {
                    const ordertArr = res.data.data.list;
                    const total = res.data.data.total;
                    dispatch(updateFailedOrderList({
                        data: ordertArr,
                        total
                    }));
                    callback && callback();
                }
            })
            .catch((err) => {
                dispatch(showErrorGlobalAlert('order-record-error'));
            });
        });
    };
};

export const getOrderDetail = (orderNo) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {

            OrderAPI.getOrderDetail({orderNo})
            .then((res) => {
                if (res.status === 200 && res.data.code === 1) {
                    return resolve(res.data.data);
                } else {
                    return reject(res.data.msg)
                }
            })
            .catch((err) => {
                alert('zheli')
                dispatch(showErrorGlobalAlert('order-record-error'));
                return reject(err);
            });
        });
    };
};

export const updateOrderPaymentStatus = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            OrderAPI.updateOrderPaymentStatus({id, status: 1})
            .then((res) => {
                if (res.status === 200) {
                    dispatch(showInfoGlobalAlert('order-payment-status-update-succeed'));
                    return resolve('success');
                }
            })
            .catch((err) => {
                dispatch(showErrorGlobalAlert('order-payment-status-update-failed'));
            });
        });
    };
};